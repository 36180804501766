
import Script from "next/script";
import { useEffect, useState, useRef } from "react";
import { BuilderComponent, Builder, builder } from '@builder.io/react';

export const additionalLinksContainer = props => (
    <>
      <div className="flex justify-center">
        <div className="grid grid-cols-3 gap-4">
          {props.blocks?.map((o) => (
            <div className="w-48 h-48 border-2 border-rose-600">
              <img className="max-h-[50px]" src={` ${o?.imageurl} `}/>
              {o.desc}
            </div>
          ))}
        </div>
      </div>
    </>
)

      // <h1>{JSON.stringify(blocks)}</h1>


// Builder.registerComponent(additionalLinksContainer,
//   {
//     name: 'additional links',
//     image: 'https://tabler-icons.io/static/tabler-icons/icons-png/link.png',
//     inputs: [],
//   }
// )