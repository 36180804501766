import React from 'react';
import { useRouter } from 'next/router';
import { BuilderComponent, builder, useIsPreviewing, Builder } from '@builder.io/react';
import DefaultErrorPage from 'next/error';
import Head from 'next/head';
import Navbar from "components/navbar";
// Replace with your Public API Key
builder.init("0b2cfe61b6754066b8e3ad5a202cca75");
import dynamic from 'next/dynamic'
import AutoInputter, {InputterWrapper} from "../components/autoInputter";
import additionalLinksContainer from "components/additionalLinksContainer";

export async function getStaticProps({ params }) {
  // Fetch the builder content
  console.log("calling static props in main page");
  const urlPath = '/' + (params?.page?.join('/') || '');
  const page = await builder
    .get('page', {
      userAttributes: {urlPath: urlPath}
    }).toPromise();

  const footer = await builder.get('footer', { userAttributes: { urlPath } }).toPromise();
  const header = await builder.get('header', { userAttributes: { urlPath } }).toPromise();

  const pages = await builder.getAll('page', {
    options: { noTargeting: true },
    limit: 100,
    query: { data: { order: { $in: [0,1] }}},
    fields: 'data.order,data.url,data.imageurl'
  })

  return {
    props: {
      page: page || null,
      pages: pages || null,
      footer: footer || null,
      header: header || null
    },
    revalidate: 5
  };
}

export async function getStaticPaths() {
  // Get a list of all pages in builder
  console.log("calling static paths on main page");
  const pages = await builder.getAll('page', {
    // We only need the URL field
    fields: 'data.url',
    options: { noTargeting: true },
  });

  return {
    paths: pages.map(page => `${page.data?.url}`),
    fallback: true,
  };
}

export default function Page({ page, footer, header, paths, pages }) {
  // additionalLinksContainer(pages)
  const router = useRouter();
  const isPreviewing = useIsPreviewing();

  if (router.isFallback) {
    return <h1>Loading...</h1>
  }

  if (!page && !isPreviewing) {
    return <DefaultErrorPage statusCode={404} />
  }

  return (
    <>
      <Head>
        <title>{page?.data.title}</title>
        <link rel="canonical" href={`https://www.rooph.io${page?.data.url}`}/>
      </Head>
      {/*<Navbar/>*/}
      {/* Render the Builder page */}
      <BuilderComponent model="header" content={header} />
      <BuilderComponent model="page" content={page} />
      <BuilderComponent model="footer" content={footer} />
    </>
  );
}


Builder.registerComponent(
  dynamic(async () => (await import('components/otpComponent')).OtpContainer),
  {
    name: 'OTP',
    image: 'https://cdn-icons-png.flaticon.com/512/1773/1773389.png',
    inputs: [],
  }
)

Builder.registerComponent(
  dynamic(async () => (await import('components/autoInputter')).InputterWrapper),
  {
    name: 'ApplicationForm',
    image: 'https://cdn-icons-png.flaticon.com/512/646/646094.png',
    inputs: [{ name: 'title', type: 'string', defaultValue: 'Subscribe to the newsletter' }],
  }
)

Builder.registerComponent(
  dynamic(async () => (await import('components/imageContainer')).imageContainer),
  {
    name: 'image container',
    image: 'https://cdn-icons-png.flaticon.com/512/833/833281.png',
    inputs: [],
  }
)



  // additionalLinksContainer,
Builder.registerComponent(
  dynamic(async () => (await import('components/additionalLinksContainer')).additionalLinksContainer),
  {
    name: 'additional links',
    image: 'https://cdn-icons-png.flaticon.com/512/659/659999.png',
    inputs: [
      {
        name: "blocks", type: 'list',
        subFields: [
          {name: "order", type: 'string', defaultValue: "1"},
          {name: "imageurl", type: 'string', defaultValue: "property-tax-loans-in-bell-county-texas.jpg"},
          {name: "desc", type: 'string', defaultValue: "Midland County, TX Property Tax Loans & Rates - Learn About Midland, TX Property Taxes"},
          {name: "url", type: 'string', defaultValue: "/midland-county-property-tax-loans"},
        ]
      }
    ],
  }
)


// Builder.registerComponent(
//   dynamic(async () => (await import('components/additionalLinksContainer')).additionalLinksContainer),
//   {
//     name: 'additional links',
//     image: 'https://tabler-icons.io/static/tabler-icons/icons-png/link.png',
//     inputs: [],
//   }
// )

// export const listContainer = props => (
//   <>
//     <p>{props.title2}</p>
//
//     {props.listItems?.map(item => <div>{item.text}</div>)}
//   </>
// )

Builder.registerComponent(
  dynamic(async () => (await import('components/listContainer')).listContainer),
  {
    name: 'list container',
    image: 'https://cdn-icons-png.flaticon.com/512/839/839860.png',
    inputs: [
      { name: 'title', type: 'string' },
      {
        name: "listitems", type: 'list',
        subFields: [{name: "text", type: 'string', defaultValue: ""}]
      },
      {
        name: "textblocks", type: 'list',
        subFields: [{name: "text", type: 'string', defaultValue: ""}]
      }
      ],
    defaults: {
      bindings: {
        'component.options.title': 'listData.data.heading',
        'component.options.listitems': 'listData.data.listitems',
        'component.options.textblocks': 'listData.data.textblocks',
      },
    },
  }
)

Builder.registerComponent(
  dynamic(async () => (await import('components/textContainer')).textContainer),
  {
    name: 'text container',
    image: 'https://cdn-icons-png.flaticon.com/512/2911/2911230.png',
    inputs: [
      { name: 'title', type: 'string' },
      {
        name: "textblocks", type: 'list',
        subFields: [{name: "text", type: 'string', defaultValue: ""}]
      }
    ],
    defaults: {
      bindings: {
        'component.options.title': 'textcontainer.data.title',
        'component.options.textblocks': 'textcontainer.data.textblocks',
      },
    },
  }
)


Builder.register('insertMenu', {
  name: 'Components',
  items: [
    { name: 'OTP' },
    { name: 'ApplicationForm' },
    { name: 'list container' },
    { name: 'text container' },
    { name: 'image container' },
    { name: 'additional links' }
  ],
})