
import { useRef, useState } from 'react'
import Script from "next/script";
import Search from "components/search";
import SearchIcon from "@/public/images/search";


const AutoInputter = ({title}) => {

  return (
    <>
      <h1 className="black">HI there {title}</h1>
    </>
  )
}

export default AutoInputter


export const InputterWrapper = ({ title }) => (
  <>
    <Script
      id="google-api"
      src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDAcUJ34Y6ddHnd110qdzqfxy0aLak0rvc&libraries=places"
    />
    <div className="w-full shadow-[4px_5px_4px_#dceaf4] ml-auto mr-auto bg-gradient-to-b from-primary to-secondary border-0 relative rounded-3xl max-w-lg">
      <Search />
      <div className="absolute left-[15px] top-[10px]">
        <SearchIcon />
      </div>
    </div>
  </>
)